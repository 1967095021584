//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
import { imgChange } from "@/commons";
import { mapState } from "vuex";
import dayjs from "dayjs";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  mixins: [MixinTeamOptions, MixinWebrtc],
  components: {
    XHeader: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    TaskModal: () => import("@/userview/task-manager/task-modal"),
    XModal: () => import("@/components/XModal"),
    ReportDetail: () => import("@/userview/report/reportDetail"),
    ProcessDataDialog: () => import("@/components/Dialogs/processDataDialog"),
    ElImageViewer,
  },
  data() {
    return {
      functionName: "成员实况",
      viewMode: "详细",
      ignoreStoreSchema: true,
      IsRealTimeScreenshot: true,
      isShowTeam: false, // 是否显示团队选择
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
        handleSizeChange: (val) => {
          this.pageData.pageSize = val;
          this.handleGetData();
        },
        handleCurrentChange: (val) => {
          this.pageData.pageIndex = val;
          this.handleGetData();
        },
      }, //分页
      members: [
        {
          UserInfo: {
            UserId: 0,
            Name: "小明",
            Avatar: null,
            Department: "技术研发部",
            ClientStatus: 0,
            LastClientOnline: "2022-10-24 10:22:33",
          },
          Attendance: {
            UpWorkTime: null,
            UpWorkStatus: -1,
            DownWorkTime: null,
            DownWorkStatus: -1,
            TotalTime: 0,
            ShouldEndTime: null,
            WorkStatus: 0,
          },
          ActiveDetails: {
            ProcessDetail: {
              Image: null,
              SoftwareName: "微信企业版",
              TotalTime: 2,
              Mouse: 40,
              keyboard: 120,
            },
            SoftwareRank: [
              {
                SoftwareName: "微信企业版",
                TotalTime: 2,
                Mouse: 40000,
                keyboard: 12000000000,
              },
              {
                SoftwareName: "QQ",
                TotalTime: 1.42,
                Mouse: 20,
                keyboard: 120,
              },
              {
                SoftwareName: "火狐浏览器",
                TotalTime: 1.42,
                Mouse: 20,
                keyboard: 120,
              },
              {
                SoftwareName: "Microsoft Visual Stodio 2022",
                TotalTime: 1.42,
                Mouse: 20,
                keyboard: 120,
              },
              {
                SoftwareName: "Chrome",
                TotalTime: 1.42,
                Mouse: 20,
                keyboard: 120,
              },
            ],
          },
          TaskDetails: {
            Current: {
              Name: "根据首页设计图编写前端页面",
              StartTime: "2022-10-24 17:12:00",
              EndTime: null,
              TotalTime: 14,
            },
            Previous: {
              Name: "根据首页设计图编写后端接口",
              StartTime: "2022-10-24 17:12:00",
              EndTime: "2022-10-26 14:12:00",
              TotalTime: 14,
            },
          },
          ReportDetails: {
            Today: {
              Status: 0,
              WorkTime: 7.5,
              OverWorkTime: 3.5,
            },
            TaskDetails: {
              Total: 5,
              Tasks: [
                {
                  Name: "根据首页设计图编写前端页面",
                  StartTime: "17:12:00",
                  EndTime: "14:12:00",
                  TotalTime: 14,
                },
                {
                  Name: "根据首页设计图编写后端接口",
                  StartTime: "17:12:00",
                  EndTime: "14:12:00",
                  TotalTime: 14,
                },
                {
                  Name: "根据首页设计图编写后端接口",
                  StartTime: "17:12:00",
                  EndTime: "14:12:00",
                  TotalTime: 14,
                },
              ],
            },
          },
        },
      ], //成员实况数据
      memberStatus: 1,
      selMem: [],
      taskData: {
        type: "", // Add Edit
        name: "",
        departmentCode: "",
      },
      reportData: {
        obj: {
          teamId: "",
          id: "",
          day: "",
        },
        FatherDetail: {},
        getDay(day) {
          //监听详情页面日期的改变
          this.obj.day = day;
        },
      },
      processData: {
        open: false,
        title: "",
        options: {
          startTime: dayjs().startOf("date").format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs().endOf("date").format("YYYY-MM-DD HH:mm:ss"),
          users: [],
          teamId: null,
          processName: [],
          softwareName: [],
          formName: [],
        },
        clientIdentity: null,
      },
      intervalIdCalcTime: null,
      intervalIdActiveDetails: null,
      NeedDataChannel: true,
    };
  },
  watch: {
    viewMode() {
      this.refreshTabVideos();
    },
  },
  computed: {
    ...mapState(["user"]),
    cmUrl() {
      return process.env.VUE_APP_CMURL;
    },
    simpleMode() {
      return this.viewMode == "精简";
    },
  },
  filters: {
    formateTime(time) {
      if (!time) {
        return;
      }
      return time.timeFormat("yyyy-MM-dd");
    },
    formateDecimal(num) {
      if (!num) {
        return 0;
      }
      num = parseFloat(num).toFixed(2);
      let reg = /00/;
      if (reg.test(num)) {
        let i = num.indexOf(".");
        num = num.slice(0, i);
        return num;
      }
      if (num[num.length - 1] == 0) {
        num = num.slice(0, num.length - 1);
        return num;
      }
      return num;
    },
  },
  methods: {
    formatClientStatusColor(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      var active = this.$D.VALUE("client_status", "活动中");
      var free = this.$D.VALUE("client_status", "空闲中");
      switch (member.UserInfo.ClientStatus) {
        case offline:
          return "danger";
        case active:
          return "success";
        case free:
          return "info";
      }
    },
    formatClientStatus(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      if (offline == member.UserInfo.ClientStatus) {
        var str = "已离线";
        if (member.UserInfo.LastClientOnline) {
          str = `${member.UserInfo.LastClientOnline.timeFormat(
            "yyyy-MM-dd HH:mm"
          )}`;
        }
        return str;
      } else {
        return this.$D.ITEM("client_status", member.UserInfo.ClientStatus).name;
      }
    },
    formatAttendanceStatusColor(status) {
      var none = this.$D.VALUE("at_state", "未打卡");
      var normal = this.$D.VALUE("at_state", "正常");
      var late = this.$D.VALUE("at_state", "迟到/早退");
      var empty = this.$D.VALUE("at_state", "缺卡");
      var skip = this.$D.VALUE("at_state", "旷工");
      var seriousLate = this.$D.VALUE("at_state", "严重迟到/早退");
      var holiday = this.$D.VALUE("at_state", "请假");
      switch (status) {
        case none:
          return "info";
        case normal:
          return "success";
        case late:
        case empty:
        case skip:
        case seriousLate:
          return "danger";
        case holiday:
          return "warning";
      }
    },
    formatAttendanceStatus(status, name) {
      return this.$D.ITEM("at_state", status)[name];
    },
    formatReportStatus(status) {
      return this.$D.ITEM("report_status", status).name;
    },
    imgChange,
    videoFullScreen(videoElement) {
      videoElement.parentElement.previousSibling.requestFullscreen();
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
      }
    },
    /**
     * 任务详情
     */
    showTaskDetail(taskId) {
      this.$modal.show("taskM");
      this.$refs.taskM.setLoading(true);
      this.$http
        .post("/Task/GetTaskDetail.ashx", {
          teamId: this.teamValue,
          Id: taskId,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.taskData = {
              type: "",
              name: "任务详情",
              departmentCode: 1,
              row: resp.data,
              xModalName: "taskM",
            };
            this.$nextTick(() => {
              this.$refs.taskM.changeEditState();
            });
          }
        })
        .finally(() => this.$refs.taskM.setLoading(false));
    },
    showReportDetail(userId) {
      var day = new Date().timeFormat("yyyy-MM-dd");
      //改变详情页面参数
      this.reportData.obj = {
        teamId: this.teamValue,
        id: userId,
        day: day,
      };
      this.$modal.show("report");
    },
    handleGetData(resetPage) {
      if (resetPage) {
        this.pageData.pageIndex = 0;
        this.pageData.totalNum = 0;
      }
      this.members = [];
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      //获得实况数据
      this.loading = true;
      this.reset();
      this.$http
        .post(
          "/Teams/MemberLive.ashx#",
          {
            userIds: this.selMem.map((item) => {
              return item.UsId;
            }),
            teamId: this.teamValue,
            status: this.memberStatus,
            pageIndex: this.pageData.pageIndex,
            pageSize: this.pageData.pageSize,
          },
          {
            timeout: 1000 * 60 * 3,
          }
        )
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.members = res.data.Data;
            this.members.forEach((item) => {
              item.MediaStream = new MediaStream();
              this.$set(item, "WebrtcState", "closed");
              this.$set(item, "activeNames", ["computer-details"]);
              this.$set(item, "allShots", []);
              this.$set(item, "showViewer", false);
              this.$set(item, "screenshotLoading", false);
              this.$set(item, "camerashotLoading", false);
            });
            this.pageData.totalNum = res.data.Total;
            this.calcWorkTime();
            this.startRefreshActiveDetails();
          }
        });
    },
    PadZero(str) {
      //补零
      return new RegExp(/^\d$/g).test(str) ? `0${str}` : str;
    },
    formateShowTime(time) {
      if (!time) {
        return "无";
      }
      if (time) {
        let _time = time * 60 * 60;
        let _seconds = parseInt(_time);
        let hours, mins, seconds;
        let result = "";
        seconds = parseInt(_seconds % 60);
        mins = parseInt((_seconds % 3600) / 60);
        hours = parseInt(_seconds / 3600);
        if (hours) {
          result =
            this.PadZero(hours) +
            ":" +
            this.PadZero(mins) +
            "'" +
            ":" +
            this.PadZero(seconds) +
            '"';
        } else {
          result =
            "00:" +
            this.PadZero(mins) +
            "'" +
            ":" +
            this.PadZero(seconds) +
            '"';
        }

        return result;
      }
    },
    computeShots(item) {
      var shots = [];
      if (item.screenShots) {
        shots = [
          ...shots,
          ...item.screenShots.map((m) => this.$url + m.imgUrl),
        ];
      }
      if (item.cameraShots) {
        shots = [
          ...shots,
          ...item.cameraShots.map((m) => this.$url + m.imgUrl),
        ];
      }
      item.allShots = shots;
    },
    async shotScreenPhoto(item) {
      if (item.screenshotLoading) {
        return;
      }
      item.screenShots = [];
      this.computeShots(item);
      //屏幕截图
      item.screenshotLoading = true;
      var resp = await this.screenShot(item.UserInfo.UserId);
      if (!resp.result) {
        item.screenshotLoading = false;
        this.$notify({
          message: `屏幕截图失败，${resp.error}`,
          type: "info",
        });
        return;
      }
      if (item.screenshotTimer) {
        clearInterval(item.screenshotTimer);
        item.screenshotTimer = null;
      }
      if (item.screenshotTimer == null) {
        let num = 0;
        item.screenshotTimer = setInterval(() => {
          num += 1;
          if (num == 30 && item.screenshotLoading == true) {
            clearInterval(item.screenshotTimer);
            item.screenshotTimer = null;
            item.screenshotLoading = false;
            this.$notify({
              type: "error",
              message: "屏幕截图请求超时",
            });
          }
        }, 1000);
      }
    },
    async shotCameraPhoto(item) {
      if (item.camerashotLoading) {
        return;
      }
      item.cameraShots = [];
      this.computeShots(item);
      //屏幕截图
      item.camerashotLoading = true;
      var resp = await this.cameraShot(item.UserInfo.UserId);
      if (!resp.result) {
        item.camerashotLoading = false;
        this.$notify({
          message: `摄像头截图失败，${resp.error}`,
          type: "info",
        });
        return;
      }
      if (item.camerashotTimer) {
        clearInterval(item.camerashotTimer);
        item.camerashotTimer = null;
      }
      if (item.camerashotTimer == null) {
        let num = 0;
        item.camerashotTimer = setInterval(() => {
          num += 1;
          if (num == 30 && item.camerashotLoading == true) {
            clearInterval(item.camerashotTimer);
            item.camerashotTimer = null;
            item.camerashotLoading = false;
            this.$notify({
              type: "error",
              message: "摄像头截图请求超时",
            });
          }
        }, 1000);
      }
    },
    /**
     * 屏幕截图
     */
    async screenShot(userId) {
      var sendSuccess = false;
      var err = "";
      try {
        var resp = await this.$http.get(
          "/User/Work/NoticeUserScreenshots.ashx",
          {
            params: { Id: userId, teamId: this.teamValue },
          }
        );
        if (resp == "") {
          sendSuccess = true;
        } else {
          err = resp.msg;
        }
      } catch (error) {
        sendSuccess = false;
        err = error;
      }
      return {
        result: sendSuccess,
        error: err,
      };
    },
    /**
     * 摄像头截图
     */
    async cameraShot(userId) {
      var sendSuccess = false;
      var err = "";
      try {
        var resp = await this.$http.get(
          "/User/Work/NoticeUserPhotographs.ashx",
          {
            params: { Id: userId, teamId: this.teamValue },
          }
        );
        if (resp == "") {
          sendSuccess = true;
        } else {
          err = resp.msg;
        }
      } catch (error) {
        sendSuccess = false;
        err = error;
      }
      return {
        result: sendSuccess,
        error: err,
      };
    },
    calcWorkTime() {
      if (this.intervalIdCalcTime == null) {
        this.intervalIdCalcTime = setInterval(() => {
          this.members.forEach((item) => {
            if (!item.Attendance.UpWorkTime || item.Attendance.DownWorkTime) {
              return;
            }
            item.Attendance.TotalTime =
              (item.Attendance.TotalTime * 3600 + 1) / 3600;
          });
        }, 1000);
      }
    },
    getMember(arr) {
      this.selMem = arr;
    },
    /**
     * websocket截图回调
     */
    socketPic(res, type) {
      // console.log(`收到${type}`);
      if (res.teamId != this.teamValue) {
        return;
      }
      this.members.forEach((item) => {
        if (res.UserId == item.UserInfo.UserId) {
          if (type == "屏幕截图") {
            item.screenshotLoading = false;
          } else if (type == "摄像头拍照") {
            item.camerashotLoading = false;
          }
          if (JSON.parse(res.imgUrl).length) {
            item.UserInfo.SoftwareName = res.title;
            let shotArr = JSON.parse(res.imgUrl);
            shotArr.forEach((val) => {
              // console.log(val);
              var img = {
                imgUrl: val.ImgUrl,
                time:
                  type +
                  "：" +
                  new Date(val.Times).toString().timeFormat("HH:mm:ss"),
              };
              if (type == "屏幕截图") {
                item.screenShots.push(img);
              } else if (type == "摄像头拍照") {
                item.cameraShots.push(img);
              }
            });
            this.computeShots(item);
            if (item.allShots.length > 0) {
              this.$nextTick(() => {
                item.showViewer = true;
              });
            }
          } else {
            this.$notify({
              message: `未获取到${type}`,
              type: "info",
            });
          }
        }
      });
    },
    WebrtcOnConnectionStateChange(room, from, state) {
      var member = this.getMemberByClientInfo(from);
      if (member) {
        member.WebrtcState = state;
        if (state == "closed") {
          member.MediaStream = new MediaStream();
        }
      }
    },
    getMemberByClientInfo(client) {
      var member = this.members.find(
        (m) => m.UserInfo.Identity == client.Identity
      );
      return member;
    },
    WebrtcOnRemoteTrack(peerConnection, event) {
      var client = peerConnection.To;
      var member = this.members.find(
        (m) => m.UserInfo.Identity == client.Identity
      );
      if (member != null) {
        member.MediaStream.addTrack(event.track);
        var ref = `video${member.UserInfo.UserId}`;
        this.$nextTick(() => {
          this.$refs[ref][0].srcObject = member.MediaStream;
        });
      }
    },
    refreshTabVideos() {
      this.members.forEach((member) => {
        var ref = `video${member.UserInfo.UserId}`;
        this.$nextTick(() => {
          this.$refs[ref][0].srcObject = member.MediaStream;
        });
      });
    },
    async callMember(identity) {
      if (this.isLocalStorage) {
        try {
          await this.Call(identity);
        } catch (error) {
          // console.log(error);
        }
      }
    },
    async sendWebrtcRequest(identity, func, ...args) {
      if (this.isLocalStorage) {
        await this.Call(identity);
        func(...args);
      }
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        await this.Call(this.processData.clientIdentity);
        var b = this.SendRequest(
          null,
          this.processData.clientIdentity,
          request
        );
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    sendWebrtcRequestNoIdentitySync(request, successCallback, failCallback) {
      this.sendWebrtcRequestNoIdentity(request)
        .then((resp) => successCallback?.(resp))
        .catch((error) => {
          failCallback?.(error);
        });
    },
    /**
     * 点击图表查看详情
     */
    handleElementClick(member, processName) {
      this.processData = {
        open: true,
        title: `进程名"${processName}"`,
        options: {
          startTime: dayjs().startOf("date").format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs().endOf("date").format("YYYY-MM-DD HH:mm:ss"),
          users: [member.UserInfo.UserId],
          teamId: this.teamValue,
          processName: processName ? [processName] : [],
          softwareName: [],
          formName: [],
        },
        clientIdentity: member.UserInfo.Identity,
      };
    },
    getActiveDetailsLocal(clientIdentity) {
      if (this.isLocalStorage) {
        var data = {
          teamId: this.teamValue,
        };
        var _this = this;
        var request = new Request();
        request.Url = "/Process/ActiveDetails";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function () {};
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            var member = _this.members.find(
              (m) => m.UserInfo.Identity == clientIdentity
            );
            if (member) {
              member.ActiveDetails = result.Data;
            }
          }
        };
        try {
          this.SendRequest(null, clientIdentity, request);
        } catch (error) {}
      }
    },
    RefreshActiveDetails() {
      if (this.isLocalStorage) {
        this.members.forEach((item) => {
          if (item.UserInfo.ClientStatus != 0) {
            if (item.WebrtcState != "open") {
              item.WebrtcState = "connecting";
            }
            this.sendWebrtcRequest(
              item.UserInfo.Identity,
              this.getActiveDetailsLocal,
              item.UserInfo.Identity
            );
          }
        });
      } else {
        var params = {
          userIds: this.members.map((item) => {
            return item.UserInfo.UserId;
          }),
          teamId: this.teamValue,
        };
        if (params.userIds.length > 0) {
          this.$http
            .post("/Teams/MemberCurrentDetail.ashx#", params)
            .then((resp) => {
              if (resp.res == 0) {
                resp.data.forEach((item) => {
                  var member = this.members.find(
                    (m) => m.UserInfo.UserId == item.UserId
                  );
                  if (member) {
                    member.ActiveDetails.ProcessDetail = item.CurrentProcess;
                  }
                });
              }
            });
        }
      }
      return this.RefreshActiveDetails;
    },
    startRefreshActiveDetails() {
      if (this.intervalIdActiveDetails == null) {
        this.intervalIdActiveDetails = setInterval(
          this.RefreshActiveDetails(),
          10000
        );
      }
    },
    reset() {
      this.HangUpAll();
      if (this.intervalIdActiveDetails) {
        clearInterval(this.intervalIdActiveDetails);
        this.intervalIdActiveDetails = null;
      }
      if (this.intervalIdCalcTime) {
        clearInterval(this.intervalIdCalcTime);
        this.intervalIdCalcTime = null;
      }
      if (this.members) {
        this.members.forEach((item) => {
          if (item.screenshotTimer) {
            clearInterval(item.screenshotTimer);
            item.screenshotTimer = null;
          }
          if (item.camerashotTimer) {
            clearInterval(item.camerashotTimer);
            item.camerashotTimer = null;
          }
        });
      }
    },
  },
  mounted() {
    this.members = [];
    const role = this.$xStorage.getItem("user-role");
    if (role.team) {
      this.teamValue = role.team;
      this.isShowTeam = false;
    } else {
      this.isShowTeam = true;
    }
    this.$E.$on("loadpic", (res) => {
      // console.log("开始截图");
    });
    this.$E.$on("loadcamerapic", (res) => {
      // console.log("开始摄像头拍照");
    });
    this.$E.$on("loadingcamerapic", (res) => {
      this.socketPic(res, "摄像头拍照");
    });
    this.$E.$on("loadingpic", (res) => {
      this.socketPic(res, "屏幕截图");
    });
  },
  clearTimer() {},
  beforeDestroy() {
    this.reset();
    this.$E.$off("loadpic");
    this.$E.$off("loadingpic");
    this.$E.$off("loadcamerapic");
    this.$E.$off("loadingcamerapic");
  },
};
